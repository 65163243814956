<template>
  <div class="index-container">
	<pcChatBox v-if="$IsPC" ></pcChatBox>
	<mvChatBox v-else ></mvChatBox>
  </div>
</template>

<script>
	
import pcChatBox from '@/components/pcChatBox'
import mvChatBox from '@/components/mvChatBox'
import storageUtils from '@/utils/storageUtils'

export default {
  components:{ pcChatBox, mvChatBox },
  name: 'index',
  created() {
	//获得来源参数
	let params = window.location.search.slice(1).split('&');
	for(let index in params){
		if(index == 0){
			//保存触发方式
			this.$store.commit('SET_MANNER', decodeURIComponent(params[index].split('=')[1]));
		}else{
			let paramArr = params[index].split('=');
			params[paramArr[0]] =decodeURIComponent(paramArr[1]);
		}
	}
	this.$store.commit('SET_URLPARAMS', params);
  },
  mounted() {
	  
	  //添加windowIframe侦听事件
	  this.$iframeConfig.init();


  }

}
</script>

<style lang="scss" scoped>

  @import "~@/styles/variables.scss";
  
  #index-container{
	height: calc(100vh);
  }

  /* 设置滚动条的样式 */
  ::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }

  /* 滚动槽 */
  ::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  /* 滚动条滑块 */
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.1);
  }

</style>
