<template>
	<div class="text-container"  >
		<div class="text-continue-title"  > 当前对话已结束，点击下方按钮继续对话 </div>
		<div class="text-continue-btn" >
			<el-button type="text" size="mini" @click="continueDialogue" >继续对话</el-button>
		</div>
	</div>
</template>

<script>
var  flag=true;
export default {
  name: 'closeConElement',
  methods:{
	  continueDialogue(){
			let msg={ type:'continue_talk' , data:this.$store.state.socket.socketParams}
			if(flag){
				//节流
				flag=false
				this.$socketConfig.socket.send(JSON.stringify(msg))
				setTimeout(()=>{
					flag=true
				},888)
			}
		
	},

	  
  }
}
</script>

<style lang="scss" scoped>
	
	.text-container{
		font-size: 13px;
		// padding: 0 10px;
		border-radius: 5px;
		color: #333333;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		.text-continue-title{
			height: 40px;
			line-height: 40px;
			font-size: 14px;
		}
		.text-continue-btn{
			height: 40px;
			line-height: 40px;
		}
	}
	
</style>
