<template>

	<div id="chatBox-container" v-loading="!socketFlag" element-loading-text="正在连接中..."
		element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.1)">


		<div id="chatBox-body">

			<div v-if="chatOrLeaveFlag" class="chatBox-body-left" :style="isMin?'height:5.1rem':''">

				<div id="chatBox-bl-message" ref="message-list" :style="isMin?'height:67%':''">

					<!-- 对话广告 -->
					<div v-if="chatAd" id="chatBox-bl-adverContainer">
						<div v-html="chatAd" style="font-size: 0.13rem"></div>
						<i id="chatBox-bl-adverImage" class="iconfont icon-laba1"></i>
					</div>

					<div id="chatBox-blm-message">

						<template v-for="(message, index) in currentMessageList">
							<!-- 自助问答 -->
							<template v-if="message.type === $constant.messageType.selfHelp">
								<self-help-element v-if="customerInfo.user_id == 1 && baseConfig.self_help == 2" />
							</template>
							<!-- 转接消息 -->
							<turn-element v-else-if="message.type === $constant.messageType.turnType"
								:isLabour="message.isLabour" />
							<!-- 对话消息 -->
							<message-item v-else class="message-item" :message="message" />
						</template>
						<closeCon-element v-if="$store.state.socket.isClose" />
					</div>

				</div>

				<div id="chatBox-bl-input">
					<div id="chatBox-bli-face">
						<el-popover ref="popover" placement="top" width="400" trigger="click">
							<div class="emojis" v-if="emoj">
								<div v-for="item in emojiName" class="emoji" :key="item" @click="chooseEmoji(item)">
									<img :src="emojiUrl + emojiMap[item]" style="width:0.3rem;height:0.3rem" />
								</div>
							</div>
						</el-popover>
						<i class="iconfont icon-smile" v-popover:popover title="发表情"></i>
						<i class="iconfont icon-tupian" title="发图片" @click="handleSendImageClick"></i>
					</div>
					<div id="chatBox-bli-textarea">
						<textarea id="text-input" ref="text-input" rows="4" resize="false" v-model="messageContent"
							placeholder="请输入文字或者粘贴图片" @focus="focus = true" @blur="focus = false"
							@keydown.enter.exact.prevent="sendText" :disabled="$store.state.socket.isClose">
				   </textarea>


						<div class="ft-text">
							<span style="font-size: 0.12rem;color: #999;">
								小到科技提供技术支持</span>
							<el-button id="btn-send" type="primary" :style="{'background-color': pcWindowThemeColor}"
								size="mini" @click="sendText">发送</el-button>
						</div>

					</div>
				</div>
			</div>
			<div v-else class="chatBox-body-left">
				<div class="leaveBox-container">
					<div class="leaveBox-body">
						<div class="leaveBox-body-hint">{{tips || '很抱歉，当前无客服在线，如需帮助，请给我们留言，我们将尽快联系您'}}</div>
						<div v-for="(item, index) in leaveFieldsFromList" :key="index"
							:class="'leaveBox-bn-'+item.field.type_name" v-if="item.is_show == 1">
							<div class="leaveBox-bn-label" style="margin-top: 0.08rem;">
								<span v-if="item.is_must == 1" class="leaveBox-bn-requir">*</span>
								<span>{{item.field.field_text}}</span>
							</div>
							<div class="leaveBox-bn-inputText">
								<el-input v-if="item.field.type_name == 'input'" v-model="item.field.value"
									placeholder="请输入内容"></el-input>
								<el-input v-else-if="item.field.type_name == 'textarea'" v-model="item.field.value"
									type="textarea" placeholder="请输入内容" :autosize="{ minRows: 2, maxRows: 6 }">
								</el-input>
								<el-select v-if="item.field.type_name == 'select'" v-model="item.field.value"
									placeholder="请选择">
									<el-option v-for="s in item.field.options" :key="s.id" :label="s.option_value"
										:value="s.id">
									</el-option>
								</el-select>
							</div>
						</div>
						<div class="leaveBox-bs-submit">
							<el-button class="leaveBox-bss-btn" type="primary"
								:style="{'background-color': pcWindowThemeColor}" @click="leaveSubmitFrom">提交
							</el-button>
						</div>
					</div>
				</div>
			</div>
			<div id="chatBox-body-right" v-if="pcWindowType">
				<div id="chatBox-br-tabs" v-if="pcWindowCommonProblem == 'faq'">
					<div id="chatBox-brt-coll">
						<el-collapse v-model="activeNames">
							<el-collapse-item v-for="(item, index) in pcWindowProblemList" :key="index"
								:title="(index+1)+'. '+item.title" :name="item.id">
								<div>{{item.content}}</div>
							</el-collapse-item>
						</el-collapse>
					</div>
				</div>
				<div id="chatBox-br-card" v-if="pcWindowCommonProblem == 'card'">
					<a v-if="pcWindowJumpLink" :href="pcWindowJumpLink" target="_blank">
						<img :src="baseImgUrl+pcCustomWindowImgUrl" /> </a>
					<img v-else :src="baseImgUrl+pcCustomWindowImgUrl" />
				</div>
			</div>
		</div>
		<!-- 图片选择框 -->
		<input type="file" id="imagePicker" ref="imagePicker" accept=".jpg, .jpeg, .png, .gif" @change="sendImage"
			style="display:none" />
		<!-- 图片预览组件 -->
		<image-previewer />

		<el-dialog title="是否发送剪切板的图片?" :visible.sync="centerDialogVisible" top>
			<div>
				<el-image :src="inputsrc.src" style="height: 2.6rem;max-width: 3.5rem;"
					:preview-src-list="[inputsrc.src]">
				</el-image>
				<img>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="centerDialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="senImg">发 送</el-button>
			</span>
		</el-dialog>

	</div>

</template>

<script>
	var isCheck = true;
	var isSubmit = true;
	import {
		mapGetters,
		mapState
	} from 'vuex'
	import MessageItem from '@/components/chat/message-item'
	import ImagePreviewer from '@/components/chat/image-previewer'
	import csTabs from '@/components/cs-tabs'
	import selfHelpElement from '@/components/chat/self-help-element'
	import turnElement from '@/components/chat/turn-element'
	import closeConElement from '@/components/chat/message-elements/closeCon-element.vue'
	import {
		emojiMap,
		emojiName,
		emojiUrl
	} from '@/utils/emojiMap'
	import {
		getad,
		problemPlist,
		selfhelpgroups,
		selfhelpfqa,
		groupSendByRobot,
		updateImg
	} from '@/api/custom'
	export default {
		components: {
			MessageItem,
			ImagePreviewer,
			csTabs,
			selfHelpElement,
			closeConElement,
			turnElement
		},
		name: 'pcChatBox',
		props: {
			tabIndex: {
				type: Number, //当前选中下标
				default () {
					return 0;
				}
			},
			messageList: {
				type: Array,
				default () {
					return [];
				}
			}
		},

		data() {
			return {
				emoj: false,
				centerDialogVisible: false,
				activeNames: '',
				inputsrc: {
					file: {},
					src: ''
				},
				messageContent: '',
				cardIndex: 0,
				cardList: ['常见问题', '公司名片'],
				emojiMap: emojiMap,
				emojiName: emojiName,
				emojiUrl: emojiUrl,
				fieldsFromList: [],
			};
		},
		computed: {
			...mapState({
				chatAd: state => state.iframe.ad.pc_msg,
				pcWindowProblemList: state => state.iframe.pcWindowProblemList,
				socketParams: state => state.socket.socketParams,
				currentMessageList: state => state.socket.currentMessageList,
				chatOrLeaveFlag: state => state.socket.fenPeiFlag,
				newMessageFlag: state => state.socket.newMessageFlag,
				socketFlag: state => state.socket.socketFlag,
				customerInfo: state => state.socket.customerInfo,
				selfHelpGroups: state => state.socket.selfHelpGroups,
				selfhelpfqas: state => state.socket.selfhelpfqas,
				baseConfig: state => state.iframe.baseConfig,
				leaveFieldsFromList: state => state.socket.form_field.guestbook,
				tips: state => state.socket.form_field.tips,
				pcWindowType: state => state.iframe.pcWindowStyle == null ? false : (state.iframe.pcWindowStyle
					.pcWindowType == 'standard' || state.socket.manner == 'link'),
				pcWindowThemeColor: state => state.iframe.pcWindowStyle == null ? '#1e90ff' : state.iframe
					.pcWindowStyle.pcWindowThemeColor,
				pcWindowCommonProblem: state => state.iframe.pcWindowStyle == null ? false : state.iframe.pcWindowStyle
					.pcWindowCommonProblem,
				pcCustomWindowImgUrl: state => state.iframe.pcWindowStyle == null ? false : state.iframe.pcWindowStyle
					.pcCustomWindowImgUrl,
				pcWindowJumpLink: state => state.iframe.pcWindowStyle == null ? false : state.iframe.pcWindowStyle
					.pcWindowJumpLink
			}),
			isMin() {
				return !this.pcWindowType;
			}
		},
		mounted() {
			if (document.querySelector('textarea')) {
				document.querySelector('textarea').addEventListener('paste', e => {
					if (e.clipboardData.types[0] == "Files") {
						const file = e.clipboardData.items[0].getAsFile()
						this.inputsrc.file = file
						this.inputsrc.src = window.URL.createObjectURL(file)
						this.centerDialogVisible = true

					}

				})

			}
			setTimeout(() => {
				this.emoj = true
			}, 30000);

		},
		watch: {
			newMessageFlag(next) {
				setTimeout(() => {
					this.scrollMessageListToButtom();
				}, 100);
			},
			chatOrLeaveFlag() {
				this.scrollMessageListToButtom();
			}
		},
		methods: {

			senImg() {
				let obj = {
					target:{
					  value:''
					},
					currentTarget: {
						files: [0]
					}
				};
				obj.currentTarget.files[0] = this.inputsrc.file
				this.sendImage(obj)
				this.centerDialogVisible = false
			},
			//顶部tab点击
			cardClick: function(index) {
				this.cardIndex = index;
			},


			//提交留言表单字段
			leaveSubmitFrom: function() {
				if(isSubmit){
					//节流
					isSubmit=false;
					let data = {};
					for (let index in this.leaveFieldsFromList) {
						if (this.leaveFieldsFromList[index].is_show == 1 && this.leaveFieldsFromList[index].is_must == 1 &&
							(this.leaveFieldsFromList[index].field.value == '' || this.leaveFieldsFromList[index].field
								.value == undefined)) {
							this.$message.info(this.leaveFieldsFromList[index].field.field_text + "不能为空！");
							return;
						}
						data[this.leaveFieldsFromList[index].field.field_name] = this.leaveFieldsFromList[index].field.value;
					}
					if (Object.keys(data).length > 0) {
					
						this.$socketConfig.sendMessge({
							type: 'message_leaving',
							data
						});
						for (let index in this.leaveFieldsFromList) {
							this.leaveFieldsFromList[index].field.value = ''
						}
						this.$message({
							message: '提交成功!',
							type: 'success'
						});
					setTimeout(()=>{
						isSubmit
					},666)
				 }
				}

			},

			chooseEmoji(item) {
				this.messageContent += item;
			},

			handleSendImageClick() {
				this.$refs.imagePicker.click();
			},

			sendImage(e) {
				this.uploadImg(e.currentTarget.files[0])
				 e.target.value = '' ;
			},

			sendText() {
				if (this.$store.state.socket.isClose) {
					this.$message.warning("对话已结束！");
					return;
				}
				if (isCheck) {
					isCheck = false
					this.sendMessage({
						identify: 'guest',
						type: 'message',
						message: {
							message_type: this.$constant.messageType.textType,
							message: this.messageContent
						}
					})
				}
			},
			sendMessage(param) {

				if (param.message.message_type == this.$constant.messageType.textType) {
					if (this.messageContent === '' || this.messageContent.trim().length === 0) {
						this.$message.warning("不能发送空消息哦！");
						isCheck = true;
						return;
					}

				}
				this.messageContent = '';
				this.$socketConfig.sendMessge(param);
				setTimeout(() => {
					isCheck = true;
				}, 333)
			},
			uploadImg(param) {

				let isImage = param.type.indexOf("image") != -1;
				let isLt2M = param.size / 1024 / 1024 < 2;
				// 检验图片格式
				if (!isImage) {
					this.$message.error("只能上传图片格式png、jpg、gif!");
					return;
				}
				if (!isLt2M) {
					this.$message.error("只能上传图片大小小于2M");
					return;
				}
				let file = param
				// 文件对象
				const form = new FormData();
				form.append("mch_token", this.socketParams.mch_token);
				form.append("file", param);
				form.append("group_id", this.customerInfo.id);
				form.append("identify", 'guest');
				param = form;
				updateImg(param).then(res => {
					if (res.code == 0) {
						let message = {
							sender_uid: 1314,
							sender_type: 'guest',
							send_time: Date.parse(new Date()) / 1000,
							type: 'imgloading',
						}
						this.$store.commit('pushCurrentMessageList', {
							'info': message,
							'type': 'one'
						});
						this.$socketConfig.sendMessge({
							identify: 'guest',
							type: 'message',
							data: {file_url:res.data.file_url},
							message: {
								message_type: this.$constant.messageType.imageType,
								message: res.data.url
							}
						})
					}

				})

			},
			onScroll({
				target: {
					scrollTop
				}
			}) {
				let messageListNode = this.$refs['message-list']
				if (!messageListNode) {
					return
				}
				if (this.preScrollHeight - messageListNode.clientHeight - scrollTop < 20) {
					this.isShowScrollButtomTips = false;
				}
			},

			//直接滚到底部
			scrollMessageListToButtom() {
				this.$nextTick(() => {
					let messageListNode = this.$refs['message-list']
					if (!messageListNode) {
						return;
					}
					messageListNode.scrollTop = messageListNode.scrollHeight;
				})
			},


		}
	};
</script>

<style lang="scss">
	.el-icon-loading {
		font-size: 35px;
	}

	.el-card__body {
		padding: 0.1rem !important;
		height: 2.5rem;

		.el-tabs__header {
			margin: 0 0 0.05rem;
		}

		.el-tabs__item {
			font-size: 12px !important;
			height: 0.3rem;
			line-height: 0.3rem;
		}
	}

	.el-select {
		width: 100%;
		height: 0.4rem;
	}
</style>

<style lang="scss" scoped>
	.ft-text {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	#chatBox-container {
		display: flex;
		flex-direction: column;
		overflow: hidden;
		height: 100%;
		border-radius: 8px 0 0 8px;
		/* #chatBox-head{
			height: calc(6vh);
			display: flex;
			align-items: center;
			background-color: #67C103;
			color: #FFFFFF;
			padding: 0 20px;
			font-size: 13px;
		} */

		#chatBox-body {
			height: 100%;
			display: flex;

			.chatBox-body-left {
				height: 570px;
				flex: 2.75;
				display: flex;
				flex-direction: column;
				position: relative;
				overflow: hidden;

				#chatBox-bl-message {
					height: 65%;
					box-sizing: border-box;
					overflow-y: scroll;
					overflow-x: hidden;
					display: flex;
					flex-direction: column;
					background-color: #ffffff;
					border-bottom: 0.02rem solid #ECECEC;
					padding: 0.1rem;

					#chatBox-bl-adverContainer {
						padding: 0.1rem;
						border-radius: 0.05rem;
						background-color: #ededed;
						position: relative;

						#chatBox-bl-adverImage {
							position: absolute;
							right: -0.03rem;
							top: -0.05rem;
							font-size: 0.2rem;
							color: #C6C8C9;
						}
					}

					#chatBox-blm-more {
						flex: 1;
						color: #C0C6CB;
						font-size: 0.13rem;
						display: flex;
						justify-content: center;
						align-items: center;
					}

					#chatBox-blm-message {
						flex: 9;
						background-color: #ffffff;

						.message-item {
							margin: 0.2rem 0;
						}
					}

					#selfHelpProblem {
						//padding: 10px;
						max-width: 90%;
						border: 0.01rem solid #F8F8F8;

						.box-card {
							.problem-row {
								overflow: hidden;
								text-overflow: ellipsis;
								white-space: nowrap;
								height: 40px;
								line-height: 40px;
								font-size: 12px;
								font-weight: 500;
								color: #303133;
								cursor: pointer;
								border-bottom: 1px solid #DCDFE6;
								max-height: 65%;
							}

						}
					}
				}

				#chatBox-bl-input {
					flex: 1;
					display: flex;
					min-height: 130px;
					background-color: #ffffff;
					flex-direction: column;
					border-radius: 8px 0 0 8px;

					#chatBox-bli-face {
						flex: 1;
						display: flex;
						align-items: center;
						box-sizing: border-box;
						padding: 0.05rem 0.1rem;

						&>i {
							cursor: pointer;
							font-size: 0.2rem;
							color: #C6C8C9;
							margin: 0 0.12rem 0 0;
						}

						&>i:hover {
							color: #333333;
						}
					}

					#chatBox-bli-textarea {
						flex: 6;
						padding: 8px;
						padding-bottom: 4px;
						display: flex;
						flex-direction: column;

						#text-input {
							font-size: 0.14rem;
							width: 100%;
							height: 100%;
							box-sizing: border-box;
							border: none;
							outline: none;
							background-color: transparent;
						}

						#btn-send {
							border: none;
						}
					}
				}

				#chatBox-bl-model {
					background-color: #000;
					width: 100%;
					height: 100%;
					position: absolute;
					bottom: 0;
					opacity: 0.5;
					z-index: 999;
				}

				.chatBox-blm-from-standard {
					position: absolute;
					bottom: 10%;
					right: 13%;
					width: 74%;
					height: 80%;
					border-radius: 5px 5px 0 0;
				}

				.chatBox-blm-from-mini {
					position: absolute;
					bottom: 0;
					right: 0;
					width: 100%;
					height: 100%;
				}

				#chatBox-blm-from {
					margin: 0 auto;
					display: flex;
					flex-direction: column;
					background-color: #FFFFFF;
					z-index: 1000;

					.chatBox-bn-requir {
						color: red;
						margin-right: 3px;
					}

					#chatBox-blm-head {
						flex: 1.4;
						display: flex;
						align-items: center;
						color: #FFFFFF;
						padding: 0 0.2rem;
						font-size: 0.13rem;
						border-radius: 0.05rem 0.05rem 0 0;
					}

					#chatBox-blm-body {
						flex: 11;
						display: flex;
						flex-direction: column;
						padding: 0 0.2rem 0.2rem 0.2rem;
						overflow-y: scroll;

						#chatBox-blm-hint {
							flex: 0.5;
							min-height: 0.4rem;
							display: flex;
							align-items: center;
							font-size: 0.12rem;
							color: #ABB2BF;
						}

						.chatBox-bn-input {
							flex: 1;
							display: flex;
							flex-direction: column;
							justify-content: center;

							.chatBox-bn-label {
								min-height: 0.2rem;
								font-size: 0.13rem;
								color: #333333;
								flex: 5;
								display: flex;
								align-items: center;
								min-height: 0.5rem;
							}

							.chatBox-bn-input {
								flex: 5;
								display: flex;
								align-items: center;
								min-height: 50px;
							}
						}

						.chatBox-bn-textarea {
							flex: 1.8;
							display: flex;
							flex-direction: column;
							justify-content: center;

							.chatBox-bn-label {
								min-height: 0.2rem;
								font-size: 0.13rem;
								color: #333333;
								flex: 3;
								display: flex;
								align-items: center;
								min-height: 0.5rem;
							}

							.chatBox-bn-input {
								flex: 7;
								display: flex;
								align-items: center;
								min-height: 0.8rem;
							}
						}

						#chatBox-blm-submit {
							flex: 1;
							display: flex;
							align-items: flex-end;
							margin-top: 0.3rem;

							#chatBox-blm-btn {
								width: 100%;
								border: none;
							}
						}
					}
				}



				.leaveBox-container {
					display: flex;
					flex-direction: column;
					background-color: #FFFFFF;
					border: 1px solid #ECECEC;
					height: calc(95vh);

					.leaveBox-bn-requir {
						color: red;
						margin-right: 3px;
					}

					.leaveBox-body {
						flex: 11;
						display: flex;
						flex-direction: column;
						padding: 0.2rem;
						overflow-y: scroll;

						.leaveBox-body-hint {
							height: 0.4rem;
							display: flex;
							align-items: center;
							font-size: 0.12rem;
							color: #ABB2BF;
						}

						.leaveBox-bn-select {
							display: flex;
							flex-direction: column;
							justify-content: center;

							.leaveBox-bn-label {
								font-size: 0.13rem;
								color: #333333;
								display: flex;
								align-items: center;
								height: 0.4rem;
							}

							.leaveBox-bn-inputText {
								display: flex;
								align-items: center;
								height: 0.4rem;

								.el-input__inner {
									height: 0.4rem;
								}
							}

						}

						.leaveBox-bn-input {
							display: flex;
							flex-direction: column;
							justify-content: center;

							.leaveBox-bn-label {
								font-size: 0.13rem;
								color: #333333;
								display: flex;
								align-items: center;
								height: 0.4rem;
							}

							.leaveBox-bn-inputText {
								display: flex;
								align-items: center;
								height: 0.4rem;

								.el-input__inner {
									height: 0.4rem;
								}
							}
						}

						.leaveBox-bn-textarea {
							display: flex;
							flex-direction: column;
							justify-content: center;

							.leaveBox-bn-label {
								font-size: 13px;
								color: #333333;
								display: flex;
								align-items: center;
								height: 0.4rem;

							}

							.leaveBox-bn-inputText {
								display: flex;
								align-items: center;
								height: 0.8rem;

								.el-input__inner {
									height: 0.8rem;
								}
							}
						}


						.leaveBox-bs-submit {
							flex: 1;
							display: flex;
							align-items: flex-end;
							margin-top: 0.3rem;

							.leaveBox-bss-btn {
								width: 100%;
								border: none;
							}
						}
					}

				}


			}

			#chatBox-body-right {
				flex: 1;
				border-left: 1px solid #ECECEC;
				display: flex;
				flex-direction: column;

				#chatBox-br-tabs {
					flex: 1;
					border-bottom: 1px solid #ECECEC;

					#chatBox-brt-coll {
						padding: 0 0.2rem;
						height: 100%;
						background-color: #FFFFFF;
					}
				}

				#chatBox-br-card {
					border-radius: 0 0 8px 0;
					overflow: hidden;
					height: 100%;

					a {
						display: block;
						width: 100%;
						height: 100%;

						img {
							display: block;
							width: 100%;
							height: 101%;
						}
					}

					img {
						display: block;
						width: 100%;
						height: 100%;

					}
				}
			}
		}

		// #chatBox-footer {
		// 	height: 2vh;
		// 	color: #C5C8C7;
		// 	font-size: 0.12rem;
		// 	border-top: 0.02rem solid #ECECEC;
		// 	padding: 0.06rem 0 0 0.1rem;
		// }
	}

	/* 设置滚动条的样式 */
	::-webkit-scrollbar {
		width: 0.03rem;
		height: 0.03rem;
	}

	/* 滚动槽 */
	::-webkit-scrollbar-track {
		border-radius: 0.1rem;
	}

	/* 滚动条滑块 */
	::-webkit-scrollbar-thumb {
		border-radius: 0.1rem;
		background: rgba(0, 0, 0, 0.1);
	}

	.emojis {
		height: 1.6rem;
		box-sizing: border-box;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		overflow-y: scroll;
	}

	.emoji {
		height: 0.4rem;
		width: 0.4rem;
		box-sizing: border-box;
	}

	textarea {
		resize: none;
	}
</style>
