<template>
 <div class="text-container" style="padding: 6px 10px;" :class="!isMine&&message.msg_button&&clickFlas?'sf':''" :style="{background:isMine?'#dbebd0':''}" >
		<template v-for="(item, index) in contentList">
		  <span :key="index" v-if="item.name === 'text'" :style="style"  v-html="item.text.replace(/[\r\n]/g,'<br />')"></span>
		  <img v-else-if="item.name === 'img'" :src="item.src" width="20px" height="20px" :key="index"/>
		</template>
		<div v-if="message.sender_uid == 1" class="text-bottom" >
			<template v-if="clickFlag">
				<el-button type="text" >已解决</el-button>
				<el-button type="text" @click="unsolved" >未解决</el-button>
			</template>
			<template v-else>
				<el-button disabled type="text" >已解决</el-button>
				<el-button disabled type="text" @click="unsolved" >未解决</el-button>
			</template>
		</div>
		<div class="stg-con" v-if="!isMine&&message.msg_button&&clickFlas" >
			<el-button @click="send(item)"  class="stg" v-for="(item, index) in  message.msg_button" :key="index">{{item}}</el-button>
		</div>
</div>
	
</template>

<script>
import { decodeText } from '@/utils/decodeText'

export default {
  name: 'TextElement',
  props: {
    message: {
      type: Object,
      required: true
    },
    isMine: {
      type: Boolean
    }
  },
  data() {
    return {
      clickFlag: true,
	  clickFlas:true,
	  style:''
    };
  },
  created(){
     var objE = document.createElement("div");
    　 objE.innerHTML = this.message.content;
       if(objE.childNodes[0].style){
            this.style=objE.childNodes[0].style.cssText
       }
  },
  computed: {
    contentList() {
	  this.message.content= this.message.content.replace(/<script/g,'script')
      return decodeText(this.message.content);
    }
  },
  methods: {
	 send(t){
		 this.$emit("msgsend",t)
		 this.clickFlas=false
	 },
	 unsolved: function(){
		 this.clickFlag = false;
		//添加一条转人工消息
		this.$store.commit('pushCurrentMessageList', {'type': 'TURN_MSG', 'isLabour': true}); 
	 } 
	  
  }
}
</script>

<style lang="scss" scoped>
	.sf{
		margin-bottom: 50px;
	}
	.stg-con{
		position: absolute;  
		display: flex;
		bottom: -15px;
		left: 0;
		width: 80%;
		flex-wrap: wrap;
	    overflow: hidden; 
	}
	.stg{	
			font-size: 13px;
		    display: inline-block !important;
		    background-color: #87CEFA;
		    border: 1px solid #87CEFA!important;
			color: #fff;
			padding:6px 8px;
			margin: 2px 5px;
			margin-left: 0;
	}
	.text-container{
		font-size: 14px;
		background-color: #FFFFFF;
		border-radius: 5px;
		color: #333333;
		box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
		word-wrap:break-word;
		word-break:normal; 

	}
	
	.text-bottom{
		margin: 10px 0;
		display: flex;
		justify-content: space-around;
		align-items: center;
		border-top: 1px solid #ECECEC;
		border-bottom: 1px solid #ECECEC;
		.el-button{
			font-size: 12px;
		}
	}
	
</style>
