<template>

	<div>

		<!-- 	<closeCon-element v-if="message.type === $constant.messageType.closeConversation"  /> -->

		<div v-if="message.type != $constant.messageType.closeConversation" class="message-container"
			:class="messagePosition">
			<!-- <div class="message-avatar">
		  <avatar :src="avatar" />
		</div> -->
		 <div class="message-content">
			<div class="message-content-head" :class="[ isMine ? 'message-ch-right' : 'message-ch-left']">
				<div style="display: flex;" v-if="!isMine"> 
				<img style="width:0.4rem;height:0.4rem;border-radius:50% ;margin-right: 5px;"
					:src="baseImgUrl+$store.state.socket.customerInfo.user_logo" >
				</div>
				<div class="message-ch-name" >{{ isMine ? '您' : (message.sender_name || '系统') }}</div>
				<div class="message-ch-date" >{{ send_date(message.send_time) }}</div>
				</div>
				<!-- 消息主体 -->
				<div class="message-content-body">
					<message-status-icon v-if="isMine" :message="message" />
					<text-element @msgsend="msgsend" v-if="message.type === $constant.messageType.textType 
					|| message.type === $constant.messageType.robotType" :isMine="isMine" :message="message" />
					<image-element v-else-if="message.type === $constant.messageType.imageType" :isMine="isMine"
						:message="message" />
					<welcome-element v-else-if="message.type === $constant.messageType.welcomeType 
					|| message.type === $constant.messageType.systemType 
					|| message.type === $constant.messageType.notAnswerType" :isMine="isMine" :message="message" />
					<div v-else-if="message.type=='loading'" class="text-container" style="padding: 6px 10px;">
					<img src="@/assets/dialogue/inputting.gif" 
					 style="width:0.14rem;height:0.14rem;">
					<span style="line-height:0.14rem;padding-left:0.14rem;">正在输入...</span>
					</div>
					<div v-else-if="message.type=='imgloading'" class="text-container" style="padding: 6px 10px;">
					<img src="@/assets/dialogue/imgloading.gif" 
					 style="width:1rem;height:1rem;">
					</div>
	
					<span v-else>暂未支持的消息类型：{{message.type}}</span>
				</div>
		  </div>
		</div>

	</div>

</template>

<script>
	import {
		mapState
	} from 'vuex'
	import {
		groupIdKey,
		visitorKey,
		getCookie,
		setCookie
	} from '@/utils/auth'
	import {
		getFullDate
	} from '@/utils/date'
	import MessageStatusIcon from './message-status-icon.vue'
	import TextElement from './message-elements/text-element.vue'
	import ImageElement from './message-elements/image-element.vue'
	import WelcomeElement from './message-elements/welcome-element.vue'
	// import closeConElement from './message-elements/closeCon-element.vue'
	export default {
		name: 'MessageItem',
		props: {
			message: {
				type: Object,
				required: true
			}
		},
		components: {
			MessageStatusIcon,
			TextElement,
			ImageElement,
			WelcomeElement
		},
		data() {
			return {
				renderDom: []
			}
		},
		computed: {
			...mapState({
				currentConversation: state => state.socket.currentConversation,
				user_id:state => state.socket.customerInfo.user_id,
				currentUserProfile: state => state.user.currentUserProfile
			}),
			date() {
				return getFullDate(new Date(this.message.time * 1000))
			},
			avatar() {
				return this.isMine ? require('@/assets/dialogue/360.png') : require('@/assets/dialogue/baidu.png');
			},
			currentConversationType() {
				return this.currentConversation.type;
			},
			isMine() {
				return this.message.sender_type=='guest';  
			},
			messagePosition() {
				if (this.isMine) {
					return 'position-right'
				} else {
					return 'position-left'
				}
			}
		},
		methods: {
			msgsend(t){
				this.$socketConfig.sendMessge({
				identify: 'guest',
				type: 'message',
				message: {
					message_type: this.$constant.messageType.textType,
					message:t
					}
				})
				
			},
			send_date(time) {
				return getFullDate(new Date(time * 1000));
			}

		}
	}
</script>

<style lang="scss" scoped>
	/* .message-wrapper {
    margin: 20px 0;
    .content-wrapper {
      display: flex;
      align-items: center;
    }
  }

  .c2c-layout {
    display: flex;
	align-items center;
    .c2c-avatar {
      .avatar {
        width: 46px;
        height: 46px;
        border-radius: 50%;
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
      }
    }
    .c2c-message {
      display: flex;
      flex-direction: column;
      // max-width 50% // 此设置可以自适应宽度，目前由bubble限制
    }

    &.position-left {
      .c2c-message {
        align-items: flex-start;
      }
    }

    &.position-right {
      flex-direction: row-reverse;
      .c2c-message {
        align-items: flex-end;
      }
    }

    &.position-center {
      justify-content: center;
    }
  }

  .c2c-layout {
    .c2c-message {
      .base {
      }
    }
  } */


.text-container{
	    background-color: #FFFFFF;
	   
	    border-radius: 5px;
	    color: #000000;
	    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
	    word-wrap: break-word;
	    word-break: normal;
}
	.message-container {
		display: flex;
		.message-avatar {
			.avatar {
				width: 46px;
				height: 46px;
				border-radius: 50%;
				box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
			}
		}

		.message-content {
			.message-content-head {
				display: flex;
				align-items: center;
				.message-ch-name {
					font-size: 14px;
					transform: scale(0.86);
				}

				.message-ch-date {
					font-size: 14px;
					transform: scale(0.92);
				}
			}

			.message-ch-right {
				display: flex;
				flex-direction: row-reverse;
				margin-bottom: 0.1rem;
				color: #BABBB9;
			}

			.message-ch-left { 
				display: flex;
				flex-direction: row;
				color: #A0A4AA;
			}

			.message-content-body {
				display: flex;
				align-items: center;
			}
		}

		&.position-left {
			.message-content {
				align-items: flex-start;
				width: 100%;
			}

			.message-content-body {
				justify-content: flex-start;
				position: relative;
				left: 55px;
				// top: -40px;
				.text-container {
					font-size: 14px;
					max-width: 80%;
				}
			}
		}

		&.position-right {
			flex-direction: row-reverse;

			.message-content {
				align-items: flex-end;
				width: 100%;
			}

			.message-content-body {
				justify-content: flex-end;
				position: relative;
				right: 4px;

				.text-container {
					max-width: 80%;

					font-size: 14px;
				}
			}
		}
	}
</style>
