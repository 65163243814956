<template>

	<div class="navbar">
		<div v-for="(item, index) in navList" :key="index" class="nav-item" :class="{current: tabIndex == index}" @click="tabClick(index)" >
			{{item}}
		</div>
	</div>

</template>
<script>
	export default {
		name: 'cs-tabs',
		data() {
			return {

			};
		},
		props: {
			tabIndex: {
				type: Number, //当前选中下标
				default () {
					return 0;
				}
			},
			navList: {
				type: Array,
				default () {
					return [];
				}
			}
		},
		methods: {

			tabClick(index){
				this.$emit('tabClick', index);
			}

		}
	};
</script>

<style lang="scss" scoped>

	.navbar{
		display: flex;
		height: 100%;
		background-color: #FFFFFF;
		.nav-item{
			flex: 1;
			display: flex;
			justify-content: center;
			align-items: center;
			height: 100%;
			color: #97C657;
			position: relative;
      cursor: pointer;
			&.current{
				&:after{
					content: '';
					position: absolute;
					left: 50%;
					bottom: 0;
					transform: translateX(-50%);
					width: 60%;
					height: 0;
					border-bottom: 2px solid #97C657;
				}
			}
		}
	}

</style>
