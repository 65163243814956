<template>
	<div class="text-container"  style="padding: 6px 10px;">
		<div v-html="message.content" ></div>
	</div>
</template>

<script>

export default {
  name: 'WelcomeElement',
  props: {
    message: {
      type: Object,
      required: true
    },
    isMine: {
      type: Boolean
    }
  }
}
</script>

<style lang="scss" scoped>
	
	.text-container{
		font-size: 14px;
		background-color: #FFFFFF;
		border-radius: 5px;
		color: #333333;
		box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
		word-wrap:break-word;
		word-break:normal; 
	}
	
</style>
