<template>
	<div class="text-container" >
		<div id="selfHelpProblem">
			<el-card class="box-card" :style="{'max-width': $IsPC ? 'calc(50vw)' : 'calc(94vw)'}" >
				<div>
					<el-tabs v-model="$store.state.socket.activeName" @tab-click="selfhelpClick">
						<el-tab-pane v-for="(item, index) in selfHelpGroups" :key="index" :label="item.group_name" :name="item.group_id+''" >
							<div class="problem-row" :style="{'max-width': $IsPC ? 'calc(50vw)' : 'calc(94vw)'}" v-for="(item, index) in selfhelpfqas" :key="index" @click="sendSelfHelp(item)" >
								{{item.title}}
							</div>
						</el-tab-pane>
					</el-tabs>
				</div>
			</el-card>
		</div>
	</div>
</template>

<script>
	
import { mapGetters, mapState } from 'vuex'
import { selfhelpfqa, groupSendByRobot } from '@/api/custom'

export default {
    name: 'selfHelpElement',
	computed: {
	  ...mapState({
		selfHelpGroups: state => state.socket.selfHelpGroups,
		selfhelpfqas: state => state.socket.selfhelpfqas
	  })
	},
	created:function(){
		
	},
    methods: {
	  
		//自助问答类别点击
		selfhelpClick(tab, event) {
			this.selfhelpfqa(tab.name);
		},
		  
		//自助问答问题获取
		selfhelpfqa(group_id){
			selfhelpfqa({
				group_id: group_id
			}).then((res) => {
				this.$store.commit('SET_SELFHELPFQAS', res.data);
			});
		},
		
		//发送自助问题
		sendSelfHelp(item) {
			this.$store.dispatch('sendMessage', {
				uuid: this.$storageUtils.getVisitorInfo().xiaoDaoVisitor.uuid,
				group_id: this.$storageUtils.getVisitorInfo().xiaoDaoGroupId,
				type: this.$constant.messageType.textType,
				content: item.title
			}).then((res) => {
				groupSendByRobot({
					group_id: this.$storageUtils.getVisitorInfo().xiaoDaoGroupId,
					type: this.$constant.messageType.textType,
					content: item.content
				}).then((res) => {
					console.log(res);
				});
			});
		}
  	
    }
}
</script>

<style lang="scss">
	
	.el-card__body{
		height: auto !important;
	}
	
</style>

<style lang="scss" scoped>
	
	#selfHelpProblem{
		max-width: 100%;
		border: 1px solid #F8F8F8;
		.box-card{
			//max-width: calc(94vw);
			.problem-row{
				//max-width: calc(94vw);
				overflow: hidden;
				text-overflow:ellipsis;
				height: 40px;
				line-height: 40px;
				font-size: 12px;
				font-weight: 500;
				color: #303133;
				cursor: pointer;
				border-bottom: 1px solid #DCDFE6;
				max-height: 65%;
			}
		}
	}
	
</style>
