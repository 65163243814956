<template>
	<div class="turn-container" >
		<span>当前问题未解决，是否转人工</span> <el-button type="text" @click="hasKefu" >转人工</el-button>
	</div>
</template>

<script>

import storageUtils from '@/utils/storageUtils'
import iframeConfig from '@/custom/iframeConfig'
import { hasKefu, guestTransfer } from '@/api/user'	
import { decodeText } from '@/utils/decodeText'

export default {
  name: 'TurnElement',
  props: {
    isLabour: {
      type: Boolean,
	  default: false
    }
  },
  methods: {
	  
	hasKefu: function(){
		let _this = this;
		hasKefu().then((res) => {
			if(res.hasKefu){
				_this.guestTransfer();
			}
		});
	},
	  
	unsolved: function(){
		//添加一条自助问答消息
		this.$store.commit('pushCurrentMessageList', {'type': 'SELF_HELP'}); 
	},
	 
	//请求接入对话，分配客服
	guestTransfer: function(){
		let _this = this;
		let visitorInfo = storageUtils.getVisitorInfo();
		if(visitorInfo){
			guestTransfer({
				group_id: this.$storageUtils.getVisitorInfo().xiaoDaoGroupId
			}).then((res) => {
				//保存客服资料
				this.$store.commit('SET_CUSTOMERINFO', JSON.parse(JSON.stringify(res.data)));
				//把转接是否成功上传到父级窗口
				iframeConfig.postMessage('fenpei', res);
				//保存显示聊天窗口或者留言窗口标识
				this.$store.commit('SET_CHATORLEAVEFLAG', res.data.is_liuyan == undefined);
			});
		}
	}
	  
  }
}
</script>

<style lang="scss" scoped>
	
	.turn-container{
		font-size: 12px;
		padding: 0 10px;
		border-radius: 5px;
		color: #333333;
		display: flex;
		justify-content: center;
		align-items: center;
		span{
			margin-right: 10px;
		}
		.el-button{
			font-size: 12px;
		}
	}
	
	/* .turn-bottom{
		margin: 10px 0;
		display: flex;
		justify-content: space-around;
		align-items: center;
		border-top: 1px solid #ECECEC;
		border-bottom: 1px solid #ECECEC;
	} */
	
</style>
