<template>

		<div id="chatBox-container" v-loading="!socketFlag" element-loading-text="正在连接中..."
			element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.1)">
		<div id="chatBox-body">

			<div v-if="chatOrLeaveFlag" id="chatBox-body-left">

				<div id="chatBox-bl-message" ref="message-list">

					<!-- 对话广告 -->
					<div v-if="chatAd" id="chatBox-bl-adverContainer">
						<div v-html="chatAd" style="font-size: 13px;"></div>
						<i id="chatBox-bl-adverImage" class="iconfont icon-laba1"></i>
					</div>

					<div id="chatBox-blm-message">
						<template v-for="(message, index) in currentMessageList">
							<!-- 自助问答 -->
							<self-help-element
								v-if="message.type === $constant.messageType.selfHelp && customerInfo.user_id == 1 && baseConfig.self_help == 2" />
							<!-- 对话消息 -->
							<message-item v-else class="message-item" :message="message" />
						</template>
						<closeCon-element v-if="$store.state.socket.isClose" />
					</div>

				</div>

				<div id="chatBox-bl-input">

					<div id="chatBox-bli-input">
						<el-input v-model="messageContent" ref="text-input" @keydown.enter.exact.prevent="handleEnter"
							@focus="delayScroll"></el-input>
					</div>
				
					<div id="chatBox-bli-face">
						<el-popover ref="popover" placement="top" trigger="click" v-model="emojiPopover">
							<div class="emojis">
								<div v-for="item in emojiName" class="emoji" :key="item" @click="chooseEmoji(item)">
									<img :src="emojiUrl + emojiMap[item]" style="width:30px;height:30px" />
								</div>
							</div>
						</el-popover>
						<i class="iconfont icon-smile" v-popover:popover title="发表情"></i>
						<el-button v-if="showSendBtn" size="small"
							:style="{'background-color': mvWindowThemeColor, 'color': '#FFFFFF'}" @click="handleEnter">
							发送</el-button>
						<i v-else class="iconfont icon-tupian" style="color: #ABB2BF;" title="发图片"
							@click="handleSendImageClick"></i>
					</div>
					
					
				</div>
				<p  class="jszc">小到提供技术支持</p>	
		

			</div>
			<div v-else id="chatBox-body-left">
				<div id="leaveBox-container">
					<div id="leaveBox-body">
						<div id="leaveBox-body-hint">{{tips || '很抱歉，当前无客服在线，如需帮助，请给我们留言，我们将尽快联系您'}}</div>
						<div v-for="(item, index) in leaveFieldsFromList" :key="index"
							:class="'leaveBox-bn-'+item.field.type_name" v-if="item.is_show == 1">
							<div class="leaveBox-bn-label" style="margin-top: 5px;">
								<span v-if="item.is_must == 1" class="leaveBox-bn-requir">*</span>
								<span>{{item.field.field_text}}</span>
							</div>
							<div class="leaveBox-bn-inputText">
								<el-input v-if="item.field.type_name == 'input'" v-model="item.field.value"
									placeholder="请输入内容"></el-input>
								<el-input v-else-if="item.field.type_name == 'textarea'" v-model="item.field.value"
									type="textarea" placeholder="请输入内容" :autosize="{ minRows: 2, maxRows: 6 }">
								</el-input>
								<el-select v-else-if="item.field.type_name == 'select'" v-model="item.field.value" 
								 style="margin-top: 3px;" placeholder="请选择">
								  <el-option  v-for="s in item.field.options" :key="s.id" :label="s.option_value" :value="s.id" >
								  </el-option>
								</el-select>
							</div>
						</div>
						<div id="leaveBox-bs-submit">
							<el-button id="leaveBox-bss-btn" type="primary"
								:style="{'background-color': mvWindowThemeColor}" @click="leaveSubmitFrom">提交
							</el-button>
						</div>
					</div>
				</div>
				<p class="jszc">小到提供技术支持</p>	
			</div>

		</div>

		

		<!-- 图片选择框 -->
		<input type="file" id="imagePicker" ref="imagePicker" accept="image/*" @change="sendImage" 
			style="display:none" />
		<!-- 图片预览组件 -->
		<image-previewer />

	</div>

</template>

<script>
	var isCheck = true;
	var isSubmit = true;
	import {
		mapGetters,
		mapState
	} from 'vuex'
	import MessageItem from '@/components/chat/message-item'
	import ImagePreviewer from '@/components/chat/image-previewer'
	import csTabs from '@/components/cs-tabs'
	import selfHelpElement from '@/components/chat/self-help-element'
	import {
		emojiMap,
		emojiName,
		emojiUrl
	} from '@/utils/emojiMap'
	import {
		getad,
		problemPlist,
		selfhelpgroups,
		selfhelpfqa,
		groupSendByRobot,
		updateImg
	} from '@/api/custom'
	import closeConElement from '@/components/chat/message-elements/closeCon-element.vue'
	export default {
		components: {
			MessageItem,
			ImagePreviewer,
			csTabs,
			selfHelpElement,
			closeConElement
		},
		name: 'mvChatBox',
		props: {
			tabIndex: {
				type: Number, //当前选中下标
				default () {
					return 0;
				}
			},
			messageList: {
				type: Array,
				default () {
					return [];
				}
			}
		},
		data() {
			return {
				showSendBtn: false,
				messageContent: '',
				cardIndex: 0,
				cardList: ['常见问题', '公司名片'],
				pcWindowProblemList: [],
				emojiPopover: false,
				emojiMap: emojiMap,
				emojiName: emojiName,
				emojiUrl: emojiUrl,
				screenHeight: document.body.clientHeight, // 这里是给到了一个默认值
				originHeight: document.body.clientHeight // 默认高度在watch里拿来做比较
			};
		},
		computed: {
			...mapState({
				socketParams: state => state.socket.socketParams,
				tips: state => state.socket.form_field.tips,
				chatAd:state => state.iframe.ad.app_msg,
				currentMessageList: state => state.socket.currentMessageList,
				leaveFieldsFromList: state => state.socket.form_field.guestbook,
				socketFlag: state => state.socket.socketFlag,
				chatOrLeaveFlag: state => state.socket.fenPeiFlag,
				newMessageFlag: state => state.socket.newMessageFlag,
				customerInfo: state => state.socket.customerInfo,
				selfHelpGroups: state => state.socket.selfHelpGroups,
				selfhelpfqas: state => state.socket.selfhelpfqas,
				baseConfig: state => state.iframe.baseConfig,
				mvWindowThemeColor: state => state.iframe.mvWindowStyle == null ? '#1e90ff' : state.iframe
					.mvWindowStyle.mvWindowThemeColor
			})
		},
		mounted() {

			// //获取对话广告
			// this.getad();
			// //查询询前表单字段
			// this.fieldsFrom();
			// //查询留言表单字段
			// this.leaveFieldsFrom();
			// //获取常见问题
			// this.problemPlist();
			
			//处理软键盘弹出底部固定定位按钮顶起问题
			let _this = this;
			window.onresize = () => {
				return (() => {
					_this.screenHeight = document.body.clientHeight;
				})()
			}
		
			    this.$nextTick(i => {
			      let pageInfo = this.getWindowConfig()
			      document.getElementById('chatBox-container').style.height = (pageInfo.pageHeight - 46) + 'px'
			      document.getElementById('chatBox-body').style.height = (pageInfo.pageHeight - 46) + 'px'
			    })

		
				
		},     
		beforeDestroy() {
			window.onresize = null; //注销window.onresize事件
		},
		watch: {
			
			chatOrLeaveFlag(){
				this.scrollMessageListToButtom();
			},
			newMessageFlag(next) {
				setTimeout(()=> {
					this.scrollMessageListToButtom();
				}, 100);
			},
			messageContent(next) {
				if (next.trim() == '') {
					this.showSendBtn = false;
				} else {
					this.showSendBtn = true;
				}
			},
			screenHeight(val) {
				// 判断软键盘弹出，收起
				if ((this.originHeight - val) > 50) {
					//键盘弹出
					this.showSendBtn = true;
				} else {
					//键盘收起
					if (this.messageContent == '') {
						this.showSendBtn = false;
					}
				}
			}
		},
		methods: {
			
			
			   getWindowConfig() {
			      let windowWidth = window.innerWidth;
			      let windowHeight = window.innerHeight;
			      if (typeof windowWidth !== 'number') {
			        if (document.compatMode === 'CSS1Compat') {
			          windowWidth = document.documentElement.clientWidth;
			          windowHeight = document.documentElement.clientHeight;
			        } else {
			          windowWidth = document.body.clientWidth;
			          windowHeight = window.body.clientHeight;
			        }
			      }
			      return {
			        windowWidth: windowWidth,
			        windowHeight: windowHeight
			      }
			    },

			
			//顶部tab点击
			cardClick: function(index) {
				this.cardIndex = index;
			},



			//提交询前表单字段
			submitFrom: function() {
				let params = {
					uuid: this.$storageUtils.getVisitorInfo().xiaoDaoVisitor.uuid
				};
				for (let index in this.fieldsFromList) {
					if (this.fieldsFromList[index].is_show == 1 && this.fieldsFromList[index].is_must == 1 && (this
							.fieldsFromList[index].field.value == '' || this.fieldsFromList[index].field.value ==
							undefined)) {
						this.$message.info(this.fieldsFromList[index].field.field_text + "不能为空！");
						return;
					}
					params[this.fieldsFromList[index].field.field_name] = this.fieldsFromList[index].field.value;
				}
				this.$store.dispatch('beforechat', params).then((res) => {
					for (let index in this.fieldsFromList) {
						this.fieldsFromList[index].field.value=''
						}
						 this.$message({
						          message: '提交成功!',
						          type: 'success'
						     });
					// this.$store.commit('SET_BEFORECHATFROMFLAG', false);
					// //重新设置iframe标题为客服昵称
					// this.$iframeConfig.postMessage('setTitle', this.customerInfo.user_nickname);
				});
			},

			//提交留言表单字段
			leaveSubmitFrom: function() {
				if(isSubmit){
					isSubmit=false;
					let data = {};
					for (let index in this.leaveFieldsFromList) {
						if (this.leaveFieldsFromList[index].is_show == 1 && this.leaveFieldsFromList[index].is_must == 1 &&
							(this.leaveFieldsFromList[index].field.value == '' || this.leaveFieldsFromList[index].field
								.value == undefined)) {
							this.$message.info(this.leaveFieldsFromList[index].field.field_text + "不能为空！");
							return;
						}
						data[this.leaveFieldsFromList[index].field.field_name] = this.leaveFieldsFromList[index].field
							.value;
					}
					this.$socketConfig.sendMessge({
						type: 'message_leaving',
						data
					});
					for (let index in this.leaveFieldsFromList) {
						this.leaveFieldsFromList[index].field.value = ''
					}
					this.$message({
						message: '提交成功!',
						type: 'success'
					});
					setTimeout(()=>{
						isSubmit=true
					},666)
				}

			},




			chooseEmoji(item) {
				this.messageContent += item;
				this.emojiPopover = false;
			},

			handleSendImageClick() {
				this.$refs.imagePicker.click();
			},

			sendImage(e) {
				this.uploadImg(e.currentTarget.files[0])
				e.target.value = '' ;
			},
		
			sendText() {
				//对话关闭不走接口请求
				if(this.$store.state.socket.isClose){
					this.$message.warning("对话已结束！");
					return;
				}
				if (isCheck) {
				isCheck = false
				this.sendMessage({
					identify: 'guest',
					type: 'message',
					message: {
						message_type: this.$constant.messageType.textType,
						message: this.messageContent
					  }
					})
				}
			},
			handleEnter() {
						this.sendText();
			},

			sendMessage(param) {

				if (param.type == this.$constant.messageType.textType) {

					if (this.messageContent === '' || this.messageContent.trim().length === 0) {
						this.$message.warning("不能发送空消息哦！");
						isCheck=true
						return;
					}

				}
				this.messageContent = '';
				this.$socketConfig.sendMessge(param);
				setTimeout(()=>{
					isCheck=true;
				},333)
			},
			uploadImg(param) {
			
				let isImage = param.type.indexOf("image") != -1;
				let isLt2M = param.size / 1024 / 1024 < 2;
				// 检验图片格式
				if (!isImage) {
					this.$message.error("只能上传图片格式png、jpg、gif!");
					return;
				}
				if (!isLt2M) {
					this.$message.error("只能上传图片大小小于2M");
					return;
				}
				let file = param
				// 文件对象
				const form = new FormData();
				form.append("mch_token", this.socketParams.mch_token);
				form.append("file", param);
				form.append("group_id", this.customerInfo.id);
				form.append("identify", 'guest');
				param = form;
				updateImg(param).then(res => {
					if (res.code == 0) {
						let message = {
							sender_uid: 1314,
							sender_type: 'guest',
							send_time: Date.parse(new Date()) / 1000,
							type: 'imgloading',
						}
						this.$store.commit('pushCurrentMessageList', {
							'info': message,
							'type': 'one'
						});
						this.$socketConfig.sendMessge({
							identify: 'guest',
							type: 'message',
							data: {file_url:res.data.file_url},
							message: {
								message_type: this.$constant.messageType.imageType,
								message: res.data.url
							}
						})
					}
			
				})
			
			},
			onScroll({
				target: {
					scrollTop
				}
			}) {
				let messageListNode = this.$refs['message-list']
				if (!messageListNode) {
					return
				}
				if (this.preScrollHeight - messageListNode.clientHeight - scrollTop < 20) {
					this.isShowScrollButtomTips = false;
				}
			},

			//直接滚到底部
			scrollMessageListToButtom() {
				this.$nextTick(() => {
					let messageListNode = this.$refs['message-list']
					if (!messageListNode) {
						return
					}
					messageListNode.scrollTop = messageListNode.scrollHeight;
				})
			},

			//延迟将聊天记录滚动到底部
			delayScroll: function() {
				let _this = this;
				_this.showSendBtn = true;
				setTimeout(function() {
					_this.scrollMessageListToButtom();
				}, 500);
			}


		}
	};
</script>

<style lang="scss">
	.el-card__body {
		padding: 10px !important;
		height: 250px;

		.el-tabs__header {
			margin: 0 0 5px;
		}

		.el-tabs__item {
			font-size: 12px !important;
			height: 30px;
			line-height: 30px;
		}
	}

	.el-input {
		height: 100%;

		.el-input__inner {
			height: 100%;
		}
	}
	.el-message-box{
		width: 3.2rem;
	}
</style>

<style lang="scss" scoped>
	.jszc{
		font-size: 14px;
		text-align: center;
		color: #999;
		height: 25px;
		min-height: 30px;
		line-height: 25px;
	}
	.el-icon-loading{
		font-size: 13px;
	}
	#chatBox-container {
		display: flex;
		flex-direction: column;
		height: calc(100vh);

		/* #chatBox-head{
			height: calc(6vh);
			display: flex;
			align-items: center;
			background-color: #67C103;
			color: #FFFFFF;
			padding: 0 20px;
			font-size: 13px;
		} */

		#chatBox-body {
			flex: 1;
			display: flex;
			height: calc(100% - 30px);

			#chatBox-body-left {
				display: flex;
				flex-direction: column;
				position: relative;
				height: 100%;
				overflow: hidden;
				#chatBox-bl-message {
					flex: 1;
					width: calc(100vw);
					box-sizing: border-box;
					overflow-y: scroll;
					overflow-x: hidden;
					display: flex;
					flex-direction: column;
					background-color: #fff;
					border-bottom: 2px solid #ECECEC;
					padding: 10px;

					#chatBox-bl-adverContainer {
						padding: 10px;
						border-radius: 5px;
						background-color: #ededed;
						margin-bottom: 10px;
						position: relative;

						#chatBox-bl-adverImage {
							position: absolute;
							right: -3px;
							top: -5px;
							font-size: 20px;
							color: #C6C8C9;
						}
					}

					#chatBox-blm-more {
						flex: 1;
						color: #C0C6CB;
						font-size: 13px;
						display: flex;
						justify-content: center;
						align-items: center;
					}

					#chatBox-blm-message {
						flex: 9;
						background-color: #ffffff;

						.message-item {
							margin: 20px 0;
						}
					}
				}

				#chatBox-bl-input {
					display: flex;
					align-items: center;
					justify-content: space-between;
					padding: 14px 10px 2px 14px;
					#chatBox-bli-input {
						flex: 9;
						display: flex;
						align-items: center;
						height: 36px;
						margin-right: 6px;
					}
					#chatBox-bli-face {
						flex: 1;
						display: flex;
						align-items: center;
						box-sizing: border-box;

						&>i {
							cursor: pointer;
							font-size: 26px;
							color: #ABB2BF;
							margin: 0 6px;
						}

						&>i:hover {
							color: #333333;
						}
					}
				}

				#chatBox-blm-from {
					position: absolute;
					bottom: 0;
					right: 0;
					width: 100%;
					height: 100%;
					margin: 0 auto;
					display: flex;
					flex-direction: column;
					background-color: #FFFFFF;
					border-radius: 5px 5px 0 0;
					z-index: 1000;

					.chatBox-bn-requir {
						color: red;
						margin-right: 3px;
					}

					#chatBox-blm-body {
						flex: 11;
						display: flex;
						flex-direction: column;
						padding: 0 20px 20px 20px;
						overflow-y: scroll;

						#chatBox-blm-hint {
							flex: 0.5;
							height: 40px;
							display: flex;
							align-items: center;
							font-size: 12px;
							color: #ABB2BF;
						}

						.chatBox-bn-input {
							display: flex;
							flex-direction: column;
							justify-content: center;

							.chatBox-bn-label {
								font-size: 13px;
								color: #333333;
								display: flex;
								align-items: center;
								height: 40px;
							}

							.chatBox-bn-input {
								display: flex;
								align-items: center;
								height: 40px;
							}
						}

						.chatBox-bn-textarea {
							display: flex;
							flex-direction: column;
							justify-content: center;

							.chatBox-bn-label {
								font-size: 13px;
								color: #333333;
								display: flex;
								align-items: center;
								min-height: 40px;
							}

							.chatBox-bn-input {
								display: flex;
								align-items: center;
								min-height: 40px;
								max-height: 80px;
							}
						}

						#chatBox-blm-submit {
							flex: 1;
							display: flex;
							align-items: flex-end;
							margin-top: 30px;

							#chatBox-blm-btn {
								width: 100%;
								border: none;
							}
						}
					}
				}



				#leaveBox-container {
					display: flex;
					flex-direction: column;
					background-color: #FFFFFF;
					border: 1px solid #ECECEC;
					height: calc(95vh);

					.leaveBox-bn-requir {
						color: red;
						margin-right: 3px;
					}

					#leaveBox-body {
						flex: 11;
						display: flex;
						flex-direction: column;
						padding: 20px;
						overflow-y: scroll;

						#leaveBox-body-hint {
							height: 40px;
							display: flex;
							align-items: center;
							font-size: 12px;
							color: #ABB2BF;
						}

						.leaveBox-bn-input {
							display: flex;
							flex-direction: column;
							justify-content: center;

							.leaveBox-bn-label {
								font-size: 13px;
								color: #333333;
								display: flex;
								align-items: center;
								height: 40px;
							}

							.leaveBox-bn-inputText {
								display: flex;
								align-items: center;
								height: 40px;

								.el-input__inner {
									height: 40px;
								}
							}
						}

						.leaveBox-bn-textarea {
							display: flex;
							flex-direction: column;
							justify-content: center;

							.leaveBox-bn-label {
								font-size: 13px;
								color: #333333;
								display: flex;
								align-items: center;
								height: 40px;
							}

							.leaveBox-bn-inputText {
								display: flex;
								align-items: center;
								height: 80px;

								.el-input__inner {
									height: 80px;
								}
							}
						}


						#leaveBox-bs-submit {
							flex: 1;
							display: flex;
							align-items: flex-end;
							margin-top: 30px;

							#leaveBox-bss-btn {
								width: 100%;
								border: none;
							}
						}
					}

				}


			}
		}

		#chatBox-footer {
			height: 30px;
			display: flex;
			justify-content: center;
			align-items: center;
			color: #ABB2BF;
			font-size: 12px;
			border-top: 1px solid #ECECEC;
		}
	}

	/* 设置滚动条的样式 */
	::-webkit-scrollbar {
		width: 3px;
		height: 3px;
	}

	/* 滚动槽 */
	::-webkit-scrollbar-track {
		border-radius: 10px;
	}

	/* 滚动条滑块 */
	::-webkit-scrollbar-thumb {
		border-radius: 10px;
		background: rgba(0, 0, 0, 0.1);
	}

	.emojis {
		height: 160px;
		box-sizing: border-box;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		overflow-y: scroll;
		justify-content: space-around;
		padding-left: 10px;
	}

	.emoji {
		height: 40px;
		width: 40px;
		box-sizing: border-box;
	}

	textarea {
		resize: none;
	}
</style>
