<template>
  <!-- <message-bubble :isMine=isMine :message=message> -->
     <!-- el-image在IE下会自动加上用于兼容object-fit的类，该类的样式在没设置图片宽高是会 GG -->
    <div>
      <img class="image-element" :src="imageUrl" @load="onImageLoaded" @click="handlePreview" />
    </div>
  <!-- </message-bubble> -->
</template>

<script>
import { Progress } from 'element-ui'
import { mapGetters } from 'vuex'
export default {
  name: 'ImageElemnt',
  props: {
    message: {
      type: Object,
      required: true
    },
    isMine: {
      type: Boolean
    }
  },

  computed: {
    ...mapGetters(['imgUrlList']),
	imageUrl() {
    if(this.message.content.indexOf('http')>-1){
      return this.message.content
    }else{
       return process.env.VUE_APP_IMG_URL + this.message.content;
		}
	}
  },
  methods: {
    onImageLoaded(event) {
      this.$bus.$emit('image-loaded', event)
    },
    handlePreview() {
      this.$bus.$emit('image-preview', {
        url: this.message.content
      })
    }
  }
}
</script>

<style lang="scss" scoped>

.image-element{
	max-width: 180px;
	cursor: zoom-in;
}

</style>
